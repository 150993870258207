import { getTokenSilent, state } from "authConfig";
import { WanderlistCategorizedLists } from "interfaces/wanderlist";
import { getJSON } from "api/request-manager";
import { initializeFeatureFlags, getFeatureFlag } from "modules/feature-flags";

/**
 * Fetches categorized Wanderlist Lists based on the provided ssoId.
 *
 * @param {string} ssoId - The Single Sign-On (SSO) ID used to retrieve categorized wanderlists.
 * @returns {Promise<WanderlistCategorizedLists>} A Promise that resolves to an object containing categorized wanderlists.
 * @throws {any} If there is an error during the API request or processing.
 */
export async function getWlListsPerCategory(ssoId: string): Promise<WanderlistCategorizedLists> {
    const wlCatLists: WanderlistCategorizedLists = {
        draftLists: [],
        publicLists: [],
        clientLists: []
    };
    let isUsingPublicWL = false;

    // eslint-disable-next-line no-useless-catch
    try {
        await initializeFeatureFlags()
            .finally(() => {
                const useToken = getFeatureFlag("consumer-2024-07-public-megamenu");
                if (useToken) {
                    isUsingPublicWL = true;
                }
            });

        let headers = {};
        if (!isUsingPublicWL) {
            await getTokenSilent();
            headers = { "Authorization": "Bearer " + state.data };
        }

        const resultsJSON = await getJSON(`${window.VIRTUOSO.apimURI}/wanderlist/lists-per-category/${ssoId}`, null, headers);
        if (resultsJSON.data.client) {
            wlCatLists.clientLists = resultsJSON.data.client;
        }
        if (resultsJSON.data.draft) {
            wlCatLists.draftLists = resultsJSON.data.draft;
        }
        if (resultsJSON.data.public) {
            wlCatLists.publicLists = resultsJSON.data.public;
        }
        return wlCatLists;

    } catch (error) {
        throw error; // Rethrow the error for the caller to handle
    }
}