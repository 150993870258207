
import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { reactive } from "vue";
import { VirtuosoUser } from "interfaces/user";

export const state = reactive({
    resolved: false,
    data: {} as string
});

// Config object to be passed to Msal on creation
const authorities: string[] = [];
authorities.push(window.VIRTUOSO.consumerMSALKnownAuthority);
export const msalConfig = {
    auth: {
        clientId: window.VIRTUOSO.consumerMSALClientId,
        authority: window.VIRTUOSO.consumerMSALAuthority,
        knownAuthorities: authorities,
        redirectUri: window.VIRTUOSO.consumerMSALRedirectURI, // Must be registered as a SPA redirectURI on your app registration
        postLogoutRedirectUri: window.VIRTUOSO.consumerMSALRedirectURI // Must be registered as a SPA redirectURI on your app registration
    },
    cache: {
        cacheLocation: "localStorage"
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        break;
                    case LogLevel.Info:
                        break;
                    case LogLevel.Verbose:
                        break;
                    case LogLevel.Warning:
                        break;
                    default:
                        break;
                }
            },
            logLevel: LogLevel.Verbose
        }
    }
};

// Add here scope for id token to be used at MS Identity Platform endpoints.
const scopes: string[] = [];
scopes.push(window.VIRTUOSO.consumerMSALScope);

const virtuosoUserEmail = window.VIRTUOSO.userEmail;
const instance = new PublicClientApplication(msalConfig);

export const loginRequest = {
    scopes: scopes,
    loginHint: virtuosoUserEmail
};
export const silentRequest = {
    scopes: ["https://graph.microsoft.com/User.Read", "https://graph.microsoft.com/Mail.Read"],
    loginHint: virtuosoUserEmail
};

export async function getTokenSilent(): Promise<void> {
    await instance.initialize();
    let token: string = null;
    try {
        token = await getToken();
    } catch (error) {
        // implement a silent interaction using Hint as indicated on the MS site for common SSO login
        // https://learn.microsoft.com/en-us/entra/identity-platform/msal-js-sso
        if (error.errorMessage.indexOf("AADB2C90077") > -1) {
            await instance.loginPopup(silentRequest)
                .catch(error => {
                    // handle error
                    console.error(error);
                    throw error;
                });
            await getToken();
        } else {
            // handle error
            console.error(error);
            throw error;
        }
    }
    return await new Promise((resolve) => {
        if (token != null) {
            state.data = token;
            state.resolved = true;
            resolve();
        } else {
            state.resolved = false;
            resolve();
        }


    });

}
export async function getToken(): Promise<string> {
    const accounts = instance.getAllAccounts();
    const userJSON = JSON.parse(window.VIRTUOSO.user);
    const virtuosoUser: VirtuosoUser = userJSON;
    let token: string | PromiseLike<string> = null;
    if (accounts.length === 0) {
        const ssoSilentResponse = await instance.ssoSilent(loginRequest);

        token = ssoSilentResponse.accessToken;
    } else {
        instance.setActiveAccount(accounts.find(x => x.localAccountId === virtuosoUser.ssoId));
        const activeAccount = instance.getActiveAccount();
        if (activeAccount === null) {
            instance.setActiveAccount(accounts[0]);
        }
        await instance.acquireTokenSilent(loginRequest)
            .then((response) => {
                if (response) {
                    token = response.accessToken;
                }
            }).catch(error => {
                // handle error
                console.error(error);
                throw error;
            });
    }
    return await new Promise((resolve) => {
        resolve(token);
    });
}
