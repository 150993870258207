<template>
    <button id="mm-close-icon" :class="['mt-1 hcp', {'-sign-in': !loggedIn}]" @click="toggleMegaMenu"><i class="icon-close-ut"></i></button>
    <div>
        <mega-menu-signedout-component v-if="!loggedIn"></mega-menu-signedout-component>
        <list-card-display-component v-else :wl-cat-lists="listCardDisplayContent" :is-loading="isLoading"></list-card-display-component>
    </div>
</template>


<script lang="ts">
    /**
    * Title: mega-menu.vue
    * Description: The mega-menu Vue component.
    * Author: Rob Hill
    * Date: 07/12/2023
    * Version: Mega Menu Phase 1
    */
    import { WanderlistCategorizedLists } from "interfaces/wanderlist";
    import { getWlListsPerCategory } from "api/wanderlist";
    import { toggleMegaMenu } from "modules/mega-menu";
    import { virtuosoUser } from "modules/user-info";
    import { defineComponent, reactive } from "vue";
    import ListCardDisplayComponent from "vue-components/wanderlist/list-card-display.vue";
    import MegaMenuSignedoutComponent from "vue-components/wanderlist/mega-menu-signedout.vue";

    export default defineComponent({
        components: {
            MegaMenuSignedoutComponent,
            ListCardDisplayComponent
        },

        data() {
            return {
                isLoading: true,
                listCardDisplayContent: reactive({
                    clientLists: [],
                    draftLists: [],
                    publicLists: []
                }) as WanderlistCategorizedLists
            };
        },
        computed: {
            loggedIn: function (): boolean {
                return (virtuosoUser.isLoggedIn);
            }
        },
        mounted: function (): void {
            toggleMegaMenu();
            if (this.loggedIn) {
                this.loadWlLists();
            }
        },
        methods: {
            toggleMegaMenu,
            loadWlLists(): void {
                getWlListsPerCategory(virtuosoUser.ssoId).then(
                    (resultsList) => {
                        if (resultsList) {
                            this.listCardDisplayContent = resultsList;
                        }
                    },
                    (error) => {
                        console.error(error);
                        console.warn("Error loading Wanderlist items");
                    }).finally(() => {
                        this.isLoading = false;
                 });
            }
        }
    });
</script>
